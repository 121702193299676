import * as React from 'react'
import {
	Accordion as AccordionChakra,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Button,
	Flex,
	Heading,
	Text
} from '@chakra-ui/react'
import UpSVG from '../icons/up.svg'
import DownSVG from '../icons/down.svg'
import { useState } from 'react'

const getDate = (eventDate, today) => {
	if (Date.parse(eventDate) === today) return 'Current Group'
	if (Date.parse(eventDate) > today) return 'Upcoming'
	return 'Already played'
}

const Accordion = (props) => {
	const [isOpen, setOpen] = useState(null)
	const { title, val, options, onSelect, hideDate } = props
	const today = Date.parse(new Date().toISOString().split('T')[0])

	return (
		<AccordionChakra
			index={isOpen}
			onChange={(val) => setOpen(val)}
			allowToggle={true}
			allowMultiple={false}
			{...props}
		>
			<AccordionItem>
				{({ isExpanded }) => (
					<>
						<AccordionButton as={Button} variant="filter">
							<Flex flex="1" alignItems="center" maxW="100%">
								<Flex
									flex="1"
									alignItems="center"
									justifyContent="center"
									w="100%"
									overflow="hidden"
								>
									<Flex
										flex="1"
										justifyContent="center"
										direction="column"
										w="100%"
										overflow="hidden"
									>
										{!!title && (
											<Text
												variant="s"
												color="secondaryGrey"
												ml="10px"
												mt="1px"
											>
												{title}
											</Text>
										)}
										<Heading
											as="h4"
											textAlign="left"
											mt={!!title ? '-5px' : '0'}
											textOverflow="ellipsis"
											overflow="hidden"
											whiteSpace="nowrap"
											textColor={
												val === -1
													? 'secondaryGrey'
													: 'white'
											}
										>
											{options[val || 0]?.name ||
												'Choose a group'}
										</Heading>
									</Flex>
								</Flex>
								<Flex
									alignItems="center"
									h="32px"
									w="32px"
									p="4px"
								>
									{isExpanded ? <UpSVG /> : <DownSVG />}
								</Flex>
							</Flex>
						</AccordionButton>
						<AccordionPanel>
							{options.map(
								(
									{
										name,
										subTitle,
										lastPlayed,
										state,
										eventDate
									},
									index
								) => (
									<Flex
										key={name}
										flex="1"
										justifyContent="center"
										alignItems="center"
										my="10px"
										cursor="pointer"
										onClick={() => {
											onSelect(index)
											setOpen(-1)
										}}
									>
										<Flex
											flex="1"
											alignItems="center"
											w="100%"
										>
											<Heading
												as="h4"
												textAlign="left"
												textOverflow="ellipsis"
												overflow="hidden"
												whiteSpace="nowrap"
											>
												{name}
											</Heading>
											{eventDate && !hideDate && (
												<Text
													variant="s"
													color="secondaryGrey"
												>
													({getDate(eventDate, today)}
													)
												</Text>
											)}
										</Flex>
									</Flex>
								)
							)}
						</AccordionPanel>
					</>
				)}
			</AccordionItem>
		</AccordionChakra>
	)
}

export default Accordion
