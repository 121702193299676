import { get, post } from './client'

const getAll = async ({ pageParam, queryKey }) => {
	const page = pageParam || 1
	const pageSize = queryKey[1]
	const player = queryKey[2] === 0
	const game = queryKey[3] || 0
	const versus = queryKey[4] || 'venue'
	const group = queryKey[5] || null
	const team = queryKey[6] || null
	const current = queryKey[7] || true
	const params = {
		player,
		game,
		versus,
		page,
		pageSize
	}

	if (current) {
		params.current = current
	}

	if (!!team) {
		params.team = team
	}
	if (!!group) {
		params.group = group
	}

	return await get('leaderboards', {
		params
	})
}

const getPublic = async ({ pageParam, queryKey }) => {
	const page = pageParam || 1
	const pageSize = queryKey[1]
	const player = queryKey[2] === 0
	const game = queryKey[3] || 0
	const versus = queryKey[4] || 'venue'
	const group = queryKey[5] || null
	const rfid = queryKey[6]
	const params = {
		player,
		game,
		versus,
		page,
		rfid,
		pageSize
	}
	if (!!group) {
		params.group = group
	}
	return await get('leaderboards/interactive', {
		params
	})
}

const getSummary = async (gameId, groupId, playerId) => {
	const params = { gameId, groupId, playerId }

	return await get(`leaderboards/summary`, { params })
}

const createTestScore = async (body) => {
	return await post('score', {
		body,
		token: 'eyJhbGciOiJIUzI1NiJ9.eyJwYXNzd29yZCI6InF3ZXJ0eTEyMzQiLCJJc3N1ZXIiOiJjcm93ZGZvcm0iLCJVc2VybmFtZSI6IkxCRyJ9.2K7UHYEjupsKxv5jqPocWHzt_6a-9IxRsFPelP5FcsU'
	})
}

export { getAll, getPublic, createTestScore, getSummary }
