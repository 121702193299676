import * as React from 'react'
import BasePage from '../components/basePage'
import { Button, Text, useToast } from '@chakra-ui/react'
import Accordion from '../components/accordion'
import { graphql, useStaticQuery } from 'gatsby'
import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { createTestScore } from '../services/leaderboards'
import { errorToastOptions } from '../helpers'
import Input from '../components/input'

const TestScoresPage = () => {
	const toast = useToast()
	const queryClient = useQueryClient()
	const [selected, setSelected] = useState(0)
	const gData = useStaticQuery(FetchAllQuery)
	const games = gData?.allMarkdownRemark?.edges || []
	const game =
		(games.length > 0 ? games[selected] : null)?.node?.frontmatter || {}

	const resolver = yupResolver(
		object()
			.shape({
				rfid: string().trim().required().min(4),
				score: string().required().min(1)
			})
			.required()
	)
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})

	const mutation = useMutation(createTestScore, {
		onSuccess: async (data) => {
			console.log('test score', data)
			await queryClient.invalidateQueries(['leaderboards'])
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const onSubmit = async (data) => {
		if (!data?.rfid || !data?.score) return
		mutation.mutate({
			gameId: parseInt(game.id),
			cardId: data.rfid,
			score: data.score
		})
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<BasePage
				access={-1}
				theme="dark"
				largeTitle={
					<>
						Game Test <br />
						Scores
					</>
				}
				primaryBtn={
					<Button
						variant="primary"
						isLoading={mutation.isLoading}
						disabled={mutation.isLoading}
						type="submit"
					>
						Test
					</Button>
				}
			>
				<Text variant="m" color="white" textAlign="center" mt="20px">
					Test scoring works between the backend and front end using
					the test fields below.
				</Text>
				<Input
					name="rfid"
					type="text"
					variant="filled"
					placeholder="RFID Code"
					mt="40px"
					register={register}
					errors={errors}
				/>
				<Accordion
					mt="50px"
					title="Game"
					val={selected}
					options={games.map((item) => {
						const { id, title } = item.node?.frontmatter || {}
						return { id, name: `Game${id}`, subTitle: title }
					})}
					onSelect={(val) => setSelected(val)}
				/>
				<Input
					name="score"
					type="text"
					variant="filled"
					placeholder="Game Score"
					mt="40px"
					register={register}
					errors={errors}
				/>
				<Text variant="m" color="white" mt="10px">
					Min/Max values for Gameid{game.id}: 150 – 750
				</Text>
			</BasePage>
		</form>
	)
}

export default TestScoresPage

const FetchAllQuery = graphql`
	query {
		allMarkdownRemark(
			sort: { order: ASC, fields: frontmatter___id }
			filter: {
				frontmatter: { title: { ne: "" } }
				fields: { name: { ne: "game-0" } }
			}
		) {
			edges {
				node {
					frontmatter {
						id
						min
						max
						duration
						rounds
						desc
						title
						image {
							childImageSharp {
								gatsbyImageData
							}
						}
					}
				}
			}
		}
	}
`
