import * as React from 'react'
import Layout from '../layout'
import TestScoresPage from '../pagesComponents/test-scores'

const TestScores = () => {
	return (
		<Layout>
			<TestScoresPage />
		</Layout>
	)
}

export default TestScores
